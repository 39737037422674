import axios from 'axios'
import {createSearchParams} from '../../utils/main'
import { Base_url} from '../../config'

//获取字典类型列表
function getDictTypeList(page) {
    let currentPage = page || 1;
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url + '/admin/getDictTypeListForPage',
            params: {
                currentPage
            }
        }).then((res) => {
            resolve(res.data)
        })
    })
}

//获取所有字典类型列表
function getDictDetailListAll(typeId) {

    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url + '/admin/getDictDetailListAll',
            params: {
                typeId:typeId
            }
        }).then((res) => {
            resolve(res.data)
        })
    })
}
//获取所有字典类型列表
function getDictDetailAll(dictTypeKey) {

    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url + '/admin/getDictDetailAll',
            params: {
                dictTypeKey:dictTypeKey
            }
        }).then((res) => {
            resolve(res.data)
        })
    })
}
//获取字典内容列表
function getDictDetailList(type,cdeName,page) {
    let currentPage = page || 1;
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url + '/admin/getDictDetailListForPage',
            params: {
                'typeId':type,
                'cdeName':cdeName,
                currentPage
            }
        }).then((res) => {
            resolve(res.data)
        })
    })
}
//保存字典类型
function saveDictType(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/save_dict_type',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//删除字典类型
function deleteDictType(typeId){
    return new Promise(resolve => {
        axios({
            method: 'POST',
            url: Base_url+'/admin/remove_dict_type',
            params:{
                typeId:typeId,
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//保存数据项
function saveDictDetail(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/save_dict_detail',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//删除数据项
function deleteDictDetail(cdeId){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/remove_dict_detail',
            params:{
                cdeId:cdeId,
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
export {
    getDictTypeList,
    getDictDetailListAll,
    getDictDetailAll,
    getDictDetailList,
    saveDictType,
    deleteDictType,
    saveDictDetail,
    deleteDictDetail
}
