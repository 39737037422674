<template>
  <div class="table">
    <div class="e-breadcrumb">字典管理</div>
    <div class="e-card">
      <div class="e-card-body" style="padding-top:35px">
        <div class="dictionary-container">
          <div>
            <el-button type="primary" size="small" @click="addTypeEvent">添加字典</el-button>
            <div class="tree-container" style="padding:10px">
              <v-tree v-loading="treeLoading" :nodes="nodes" :current-change="currentChange">
                <template slot-scope="{node}">
                  <a href="javascript:;" @click.stop="editTypeEvent(node)" class="v-tree-edit"></a>
                  <a href="javascript:;" @click.stop="deleteTypeEvent(node)" class="v-tree-remove"></a>
                </template>
              </v-tree>
            </div>
          </div>
          <div class="order-table-wrapper" v-if="currentSelect">
            <div class="e-card">
              <div class="e-card-body" style="padding-top:35px">
                <div class="e-handle-box" style="margin-bottom: 50px">
                  <div class="handle-box">
                    <span class="v-form-label">数据项名称:</span>
                    <el-input placeholder="请输入数据项名称" size="small" style="width:265px" v-model="cdeName"
                              clearable
                              class="handle-input mr10"></el-input>
                    <div style="margin-left: 290px">
                      <el-row type="flex" justify="end">
                        <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                        </el-button>
                        <el-button size="small" class="e-button" type="primary" @click="addCdeEvent">新增
                        </el-button>
                      </el-row>
                    </div>
                  </div>
                </div>

                <div class="e-table-wrapper" style="margin-top: -15px">
                  <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
                    <thead>
                    <tr>
                      <th width="150" class="th-l">类型标识</th>
                      <th width="150" class="th-l">数据项代码</th>
                      <th width="130" class="th-l">数据项名称</th>
                      <th width="130" class="th-l">备注</th>
                      <th width="150" class="th-l">操作</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                      <td class="td-l">{{row.dictTypeKey}}</td>
                      <td class="td-l">{{row.dictValue}}</td>
                      <td class="td-l">{{row.name}}</td>
                      <td class="td-l">{{row.note}}</td>
                      <td class="td-l">
                        <el-button type="text" icon="el-icon-edit" @click="editCdeEvent(index)">编辑</el-button>
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            class="red"
                            @click="deleteCdeEvent(index)"
                        >删除
                        </el-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div v-if="list.length" class="pagination">
                    <el-pagination
                        background
                        @current-change="handleCurrentChange"
                        :pager-count="7"
                        layout="prev, pager, next,total"
                        :total="pagination.count"
                        :page-count="pagination.pages"
                        :current-page="pagination.pageNumber"
                    ></el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="deleteTypeVisible" width="300px" center>
      <div class="del-dialog-cnt">同时删除类型下数据项，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="delType">确 定</el-button>
        <el-button size="small" @click="deleteTypeVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="deleteCdeVisible" width="300px" center>
      <div class="del-dialog-cnt">是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="delCde">确 定</el-button>
        <el-button size="small" @click="deleteCdeVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <InsertType
        v-if="addTypeVisible"
        :addTypeVisible="addTypeVisible"
        @closeTypeInsert="closeTypeInsert"
        @addTypeSuccess="addTypeSuccess"
    />
    <EditType
        v-if="editTypeVisible"
        :editTypeVisible="editTypeVisible"
        @closeTypeEdit="closeTypeEdit"
        @editTypeSuccess="editTypeSuccess"
        :editData="editData"
    />
    <InsertCde
        v-if="addCdeVisible"
        :addCdeVisible="addCdeVisible"
        @closeCdeInsert="closeCdeInsert"
        @addCdeSuccess="addCdeSuccess"
        :dictTypeId="dictTypeId"
        :dictTypeKey="dictTypeKey"
    />
    <EditCde
        v-if="editCdeVisible"
        :editCdeVisible="editCdeVisible"
        @closeCdeEdit="closeCdeEdit"
        @editCdeSuccess="editCdeSuccess"
        :editItem="editItem"
    />
  </div>
</template>

<script>
import InsertType from "./insertType";
import EditType from "./editType";
import InsertCde from "./insertDetail";
import EditCde from "./editDetail";
import vTree from '@/components/common/v-tree/tree';
import {dataFormat} from "../../../utils/main";
import {
    getDictTypeList,
    getDictDetailList,
    deleteDictType,
    deleteDictDetail
} from "../../../api/sys/dict";

export default {
    data() {
        return {
            treeLoading: false,
            tableLoading: false,
            cdeName: "",
            nodes: [],
            map: {},
            options: {
                data: {
                    simpleData: {
                        idKey: "id",
                        pIdKey: "parentId"
                    },
                    key: {
                        name: "name",
                        children: "children"
                    }
                }
            },
            currentSelect: "",
            dictTypeId: "",
            dictTypeKey: "",
            pagination: {
                count: 0,
                pageNumber: 0,
                pageSize: 0,
                pages: 1
            },
            list: [],
            titles: ["大类名称", "编辑", "删除"],
            btns: ["edit", "delete"],
            addTypeVisible: false,
            editTypeVisible: false,
            deleteTypeVisible: false,
            editData: {},
            addCdeVisible: false,
            editCdeVisible: false,
            deleteCdeVisible: false,
            editItem: {},
            selItem: {}
        };
    },
    async created() {
        this.requestSearch();
    },
    methods: {
        handleCurrentChange(page) {
            this.currentRow(this.map[this.currentSelect], page);
        },
        searchEvent(page) {
            this.currentRow(item, page);
        },
        async requestSearch() {
            this.treeLoading = true;
            let dataSet = await getDictTypeList();
            let res = dataSet.dataSet
            let data = dataFormat(this.options, res.list);
            this.nodes = data.list;
            this.map = data.map;
            this.treeLoading = false;
        },
        currentChange(item) {
            this.currentRow(item, 1);
        },
        async currentRow(item, page) {
            this.tableLoading = true;
            this.currentSelect = item.id;
            console.log(this.currentSelect);
            this.dictTypeKey = item.dictTypeKey;
            this.cdeName = null;
            let dataSet = await getDictDetailList(item.id, this.cdeName, page);
            let res = dataSet.dataSet
            let list = res.list;
            this.list = list;
            this.pagination = {
                count: res.count,
                pageNumber: res.pageNumber,
                pageSize: res.pageSize,
                pages: res.pages
            };
            this.tableLoading = false;
        },
        async searchEvent() {
            let dataSet = await getDictDetailList(this.currentSelect, this.cdeName, 1);
            let res = dataSet.dataSet
            let list = res.list;
            this.list = list;
            this.pagination = {
                count: res.count,
                pageNumber: res.pageNumber,
                pageSize: res.pageSize,
                pages: res.pages
            };
        },
        addTypeEvent() {
            this.addTypeVisible = true;
        },
        editTypeEvent(node) {
            this.editData = {
                id: node.id,
                dictTypeKey: node.dictTypeKey,
                name: node.name
            };
            this.editTypeVisible = true;
        },
        deleteTypeEvent(node) {
            this.id = node.id;
            this.deleteTypeVisible = true;
        },
        addCdeEvent() {
            this.dictTypeKey = this.dictTypeKey;
            this.dictTypeId = this.currentSelect;
            this.addCdeVisible = true;
        },

        editCdeEvent(index) {
            this.editItem = this.list[index];
            this.editCdeVisible = true;
        },

        deleteCdeEvent(index) {
            this.selItem = this.list[index];
            this.deleteCdeVisible = true;
        },

        addTypeSuccess() {
            this.closeTypeInsert();
            this.requestSearch();
        },

        closeTypeInsert() {
            this.addTypeVisible = false;
        },
        editTypeSuccess() {
            this.closeTypeEdit();
            this.requestSearch();
            this.searchEvent();
        },
        closeTypeEdit() {
            this.editTypeVisible = false;
        },
        addCdeSuccess() {
            this.closeCdeInsert();
            this.searchEvent();
        },

        closeCdeInsert() {
            this.addCdeVisible = false;
        },
        editCdeSuccess() {
            this.closeCdeEdit();
            this.searchEvent();
        },
        closeCdeEdit() {
            this.editCdeVisible = false;
        },
        async delType() {
            let res = await deleteDictType(this.id);
            if (res.code == 200) {
                // this.currentSelect = null;
                this.dictTypeKey = null;
                this.requestSearch();
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: "success"
                });
                this.deleteTypeVisible = false;
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: "error"
                });
            }
        },
        async delCde() {
            let res = await deleteDictDetail(this.selItem.id);
            if (res.code == 200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: "success"
                });
                this.deleteCdeVisible = false;
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: "error"
                });
            }
        }
    },
    components: {
        vTree,
        InsertType,
        EditType,
        InsertCde,
        EditCde
    }
};
</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .dictionary-container {
    display: flex;
  }

  .tree-container {
    margin-top: 20px;
    margin-right: 10px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 280px;
    flex: 0 0 280px;
    width: 280px;
    /*  height: 600px;*/
    overflow-y: auto;
    border: 1px #ebeef5 solid;
  }

  .table-container {
    flex: 1;
    display: inline-block;
  }
  .off {
    background-color: #fff;
  }
  .on {
    background-color: #ececea;
  }
</style>
