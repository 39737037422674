<template>
  <el-dialog title="编辑" width="600px" :visible.sync="editCdeVisible" :before-close="closeCdeEvent">
    <el-form ref="form" label-width="100px">
      <el-form-item size="small" label="类型标识:" required>
        <el-input v-model="item.dictTypeKey" readonly maxlength="16" style="width: 445px;" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="数据项代码:" required>
        <el-input v-model="item.dictValue" maxlength="16" style="width: 445px;" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="数据项名称:" required>
        <el-input v-model="item.name" maxlength="16" style="width: 445px;" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="备注:" required>
        <el-input v-model="item.note" maxlength="32" style="width: 445px;" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" type="primary" @click="saveCde">确 定</el-button>
    <el-button size="small" @click="closeCdeEvent">取 消</el-button>
  </span>
  </el-dialog>
</template>
<script>
import {saveDictDetail} from '../../../api/sys/dict'

export default {
    data() {
        return {
            show: false,
            item: this.editItem
        }
    },
    props: {
        editItem: Object,
        editCdeVisible: Boolean
    },
    methods: {
        closeCdeEvent() {
            this.$emit('closeCdeEdit')
        },
        async saveCde() {
            let data = {
                id: this.item.id,
                dictTypeId: this.item.dictTypeId,
                dictValue: this.item.dictValue,
                name: this.item.name,
                note: this.item.note
            };
            let res = await saveDictDetail(data);
            if (res.code == 200) {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.$emit('editCdeSuccess')
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
