<template>
  <el-dialog title="添加" width="600px" :visible.sync="addTypeVisible" :before-close="closeTypeEvent">
    <el-form ref="form" label-width="100px">
      <el-form-item size="small" label="大类标识:" required>
        <el-input v-model="dictTypeKey" maxlength="16" style="width: 430px;" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="大类名称:" required>
        <el-input v-model="name" maxlength="16" style="width: 430px;" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" type="primary" @click="saveType">确 定</el-button>
    <el-button size="small" @click="closeTypeEvent">取 消</el-button>
  </span>
  </el-dialog>
</template>
<script>
import {saveDictType} from '../../../api/sys/dict'

export default {
    data() {
        return {
            dictTypeKey: '',
            name: ''
        }
    },
    props: {
        addTypeVisible: Boolean
    },
    methods: {
        closeTypeEvent() {
            this.$emit('closeTypeInsert')
        },
        async saveType() {
            let data = {
                dictTypeKey: this.dictTypeKey,
                name: this.name
            };
            let res = await saveDictType(data);
            if (res.code == 200) {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.$emit('addTypeSuccess')
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
